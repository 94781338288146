<template>
  <div id="PayView" style="height: 100%">
    <van-overlay :show="loading" style="text-align: center">
      <van-loading type="spinner">加载中...</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import {payInfoByPayNo, unifyPay} from "@/api/insure";
import {Dialog, Notify} from "vant";

export default {
  name: "PayView",
  mounted() {
    this.onLoad();
  },
  data() {
    return {
      loading: false,
      payNo: "",
      openId: "",
    }
  },
  methods: {
    onLoad() {
      this.payNo = this.$route.query.payNo;
      this.openId = this.$route.query.openId;
      this.loading = true;
      payInfoByPayNo({payNo: this.payNo}).then(res => {
        this.loading = false;
        if (200 === res.code) {
          var payInfo = res.data;
          if (payInfo && 1 === payInfo.payStatus) {
            Notify({type: 'warning', message: "该订单已经支付，请勿重复支付！"});
          } else {
            this.payJudge();
          }
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        Notify({type: 'warning', message: "支付信息查询失败！"});
        this.loading = false;
      });
    },

    payJudge() {
      //支付判断
      if (this.openId != null && this.openId !== '') {
        //微信公众支付
        var payInfo = {
          payType: 18,
          payNo: this.payNo,
          openId: this.openId
        };
        this.loading = true;
        unifyPay(payInfo).then(res => {
          if (200 === res.code) {
            this.onBridgeReady(res.data);
          } else {
            Notify({type: 'warning', message: res.message});
          }
        }).finally(()=>{
          this.loading = false;
        });
      } else {
        var userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.match(/Alipay/i)=="alipay") {
          //支付宝
          var alipayInfo = {
            payType: 18,
            payNo: this.payNo
          };
          this.loading = true;
          unifyPay(alipayInfo).then(res => {
            if (200 === res.code) {
              window.location.href = res.data;
            } else {
              Notify({type: 'warning', message: res.message});
            }
          }).finally(()=>{
            this.loading = false;
          });
        } else if (userAgent.match(/MicroMessenger/i)=="micromessenger") {
          //微信
          //微信授权
          // var local = window.location.href;
          // getOauthPageUrl({payType: 1, redirectPageBase64Url: btoa(local)}).then(res => {
          //   if (200 === res.code) {
          //     window.location.href = res.data;
          //   }else {
          //     Notify({type: 'warning', message: res.message});
          //   }
          // }).catch(() => {
          //   Notify({type: 'warning', message: "微信授权失败！"});
          // });

          Dialog.alert({
            title: '提示',
            message: '请使用【支付宝】进行扫码支付！',
          }).then(() => {
            console.log("请使用【支付宝】进行扫码支付！");
          });
        } else  {
          //其他
          console.log("其他支付！");
          Dialog.alert({
            title: '提示',
            message: '请使用【支付宝】进行扫码支付！',
          }).then(() => {
            console.log("请使用【支付宝】进行扫码支付！");
          });
        }
      }
    },

    onBridgeReady(params) {
      WeixinJSBridge.invoke( // eslint-disable-line
          'getBrandWCPayRequest',
          {
            appId: params.appId, // 公众号名称，由商户传入
            timeStamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
            package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: params.paySign // 支付签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 这里是查询订单是否支付完成，然后执行成功和失败的业务逻辑
              setTimeout(() => {
                this.checkPayInfo();
              }, 2000);
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              alert('支付失败！')
            }
          }
      )
    },
    checkPayInfo(){
      payInfoByPayNo({payNo: this.payNo}).then(res => {
        if (200 === res.code) {
          var payInfo = res.data;
          if (payInfo && 1 === payInfo.payStatus) {
            this.$router.push({path: '/insure/paySuccess'});
          } else {
            Notify({type: 'warning', message: "尚未收到支付结果信息，请稍等或者联系客服！"});
          }
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        Notify({type: 'warning', message: "支付信息查询失败！"});
        this.finished = true;
      });
    },

  }
}
</script>
